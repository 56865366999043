<template>
  <base-modal-side :is-dimmed="true" @closeModal="actions.closeModal()">
    <template #modalHeader>
      <div class="header">
        <h3>투표 화이트 리스트 설정</h3>
        <p class="sub-text-s2 text-gray-second">
          허용할 국가를 선택하여 화이트 리스트에 추가하세요.
        </p>
      </div>
    </template>

    <template #modalBody>
      <div class="geo-fence-form">
        <div class="selected-country">
          <h4 class="sub-text-s2">화이트 리스트</h4>
          <ul>
            <li
              v-for="(item, index) in state.selectedCountryList"
              :key="`${item}-${index}`"
            >
              <button
                class="sub-text-s3 tag-btn"
                @click="
                  () => {
                    actions.removeCountry(item);
                  }
                "
              >
                {{ `${item}` }}
                <span>
                  <close-icon></close-icon>
                </span>
              </button>
            </li>
          </ul>
        </div>
        <div class="country-list">
          <div>
            <h4 class="sub-text-s2">화이트 리스트 추가</h4>
            <div class="search-wrapper">
              <input-search-new
                v-model="state.query"
                class="search"
                placeholder="국가명을 입력해주세요."
                :search-type="'typingText'"
                @search="actions.searchCountry"
              ></input-search-new>
            </div>
          </div>
          <ul v-show="!state.isQuery">
            <li v-for="(value, key) in state.countrylistNew" :key="key">
              <hr />
              <button
                class="sub-text-s3"
                @click="
                  () => {
                    actions.selectCountry(key);
                  }
                "
              >
                {{ key }} Continent
              </button>
              <hr />
              <ul>
                <li v-for="(sitem, index) in value" :key="`${sitem}-${index}`">
                  <button
                    class="sub-text-s3"
                    @click="
                      () => {
                        actions.selectCountry(sitem);
                      }
                    "
                  >
                    {{ `${sitem}` }}
                  </button>
                </li>
              </ul>
            </li>
          </ul>

          <ul v-show="state.isQuery">
            <li
              v-for="(item, index) in state.countrylist"
              :key="`${item}-${index}`"
            >
              <button
                class="sub-text-s3"
                @click="
                  () => {
                    actions.selectCountry(item);
                  }
                "
              >
                {{ `${item}` }}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </template>
    <template #modalFooter>
      <div class="button-wrapper">
        <button-basic
          class="cancel"
          text="취소"
          text-size="s1"
          bg-color="#ECF1F4"
          color="#0d0d0d"
          @action="actions.closeModal()"
        ></button-basic>
        <button-basic
          class="save"
          text="저장"
          text-size="s1"
          :is-loading="state.isSaving"
          :disabled="!state.isActiveSaveBtn"
          @action="actions.createGeoFence"
        ></button-basic>
      </div>
    </template>
  </base-modal-side>
</template>

<script>
import { reactive, computed, onBeforeMount } from "vue";
import BaseModalSide from "../../../../components/common/BaseModalSide.vue";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";
import ApiService from "@/api";
import CloseIcon from "@/components/console/icons/CloseIcon.vue";
import InputSearchNew from "@/components/console/inputs/InputSearchNew.vue";
import { cloneDeep } from "lodash";

export default {
  name: "GeoFenceCreateModal",
  components: {
    InputSearchNew,
    CloseIcon,
    ButtonBasic,
    BaseModalSide,
  },
  props: {
    whiteListCountry: {
      type: Array,
      required: true,
    },
  },
  emits: ["hideModal", "saveComplete"],
  setup(props, { emit }) {
    const state = reactive({
      isActiveSaveBtn: computed(() => {
        return !state.isSaving;
      }),
      countrylist: [],
      testList: [],
      selectedCountryList: [],
      query: "",
      storedCountryList: [],
      countryOriginalData: [],
      isSaving: false,
      countrylistNew: {},
      isQuery: computed(() => {
        return state.query.toLowerCase().trim().length > 0;
      }),
    });

    const continentGroupedCodes = {
      Europe: [
        "AD",
        "AL",
        "AM",
        "AQ",
        "AT",
        "AX",
        "AZ",
        "BA",
        "BE",
        "BG",
        "BO",
        "BY",
        "CH",
        "CZ",
        "DE",
        "DK",
        "DZ",
        "EC",
        "EE",
        "ES",
        "FI",
        "FO",
        "FR",
        "GB",
        "GE",
        "GG",
        "GI",
        "GR",
        "HR",
        "HU",
        "IE",
        "IM",
        "IN",
        "IS",
        "IT",
        "JE",
        "KY",
        "LI",
        "LT",
        "LU",
        "LV",
        "MC",
        "MD",
        "ME",
        "MK",
        "MQ",
        "MS",
        "MT",
        "NC",
        "NL",
        "NO",
        "PF",
        "PL",
        "PT",
        "RE",
        "RO",
        "RS",
        "RU",
        "SE",
        "SI",
        "SK",
        "SM",
        "TN",
        "UA",
        "XK",
      ],
      Asia: [
        "AE",
        "AF",
        "BD",
        "BH",
        "BN",
        "BT",
        "CN",
        "CY",
        "HK",
        "ID",
        "IL",
        "IQ",
        "IR",
        "JP",
        "KG",
        "KH",
        "KR",
        "KW",
        "KZ",
        "LA",
        "LB",
        "LK",
        "MM",
        "MN",
        "MO",
        "MV",
        "MY",
        "NP",
        "OM",
        "PH",
        "PK",
        "PS",
        "QA",
        "SA",
        "SG",
        "SY",
        "TH",
        "TJ",
        "TL",
        "TM",
        "TR",
        "TW",
        "UZ",
        "VN",
      ],
      "South-America": [
        "AG",
        "AI",
        "AN",
        "AR",
        "BB",
        "BQ",
        "BR",
        "CL",
        "CO",
        "GD",
        "GF",
        "GY",
        "JM",
        "PA",
        "PE",
        "PY",
        "SR",
        "TT",
        "UY",
        "VE",
      ],
      "North-America": [
        "AN",
        "BM",
        "BS",
        "BZ",
        "CA",
        "CR",
        "CU",
        "CW",
        "DM",
        "DO",
        "GL",
        "GP",
        "GT",
        "GU",
        "HN",
        "HT",
        "KN",
        "LC",
        "MF",
        "MH",
        "MP",
        "MX",
        "NI",
        "PM",
        "PR",
        "PW",
        "SV",
        "SX",
        "TC",
        "US",
        "VC",
        "VG",
        "VI",
      ],
      Africa: [
        "AO",
        "BF",
        "BI",
        "BJ",
        "BW",
        "CD",
        "CF",
        "CG",
        "CI",
        "CM",
        "CV",
        "DJ",
        "EG",
        "ER",
        "ET",
        "GA",
        "GH",
        "GM",
        "GN",
        "GW",
        "JO",
        "KE",
        "LS",
        "LY",
        "MA",
        "MG",
        "ML",
        "MR",
        "MU",
        "MW",
        "MZ",
        "NA",
        "NE",
        "NG",
        "RW",
        "SC",
        "SD",
        "SL",
        "SN",
        "SO",
        "SZ",
        "TD",
        "TG",
        "TZ",
        "UG",
        "YE",
        "YT",
        "ZA",
        "ZM",
        "ZW",
      ],
      Oceania: [
        "AS",
        "AU",
        "AW",
        "CK",
        "FJ",
        "FM",
        "KI",
        "LR",
        "NF",
        "NR",
        "NZ",
        "PG",
        "SB",
        "TO",
        "TV",
        "VU",
        "WF",
        "WS",
      ],
    };

    function groupCountriesByContinent(
      bigcCountryCodes,
      continentGroupedCodes
    ) {
      const continentCountryNames = {};
      const allCodes = new Set(bigcCountryCodes.map((country) => country.code));

      for (const [continent, codes] of Object.entries(continentGroupedCodes)) {
        continentCountryNames[continent] = codes
          .map((code) => {
            const country = bigcCountryCodes.find(
              (country) => country.code === code
            );
            return country ? country.name : null;
          })
          .filter((name) => name !== null);

        codes.forEach((code) => allCodes.delete(code));
      }

      continentCountryNames["other"] = Array.from(allCodes)
        .map((code) => {
          const country = bigcCountryCodes.find(
            (country) => country.code === code
          );
          return country ? country.name : null;
        })
        .filter((name) => name !== null);

      return continentCountryNames;
    }

    onBeforeMount(async () => {
      const whiteList = props.whiteListCountry;
      await ApiService.getCountries().then((res) => {
        state.countryOriginalData = res.data.data;
        if (whiteList.length === 0) {
          for (const element of state.countryOriginalData) {
            state.storedCountryList.push(element.name);
          }
        } else {
          let copy = cloneDeep(res.data.data);
          whiteList.forEach((item) => {
            state.selectedCountryList.push(item.country.name);

            let index = copy.findIndex((test) => {
              return test.code === item.country.code;
            });
            copy.splice(index, 1);
          });
          copy.forEach((item) => {
            state.storedCountryList.push(item.name);
          });
        }
        state.countrylist = state.storedCountryList;

        const continentCountryNames = groupCountriesByContinent(
          state.countryOriginalData,
          continentGroupedCodes
        );
        state.countrylistNew = continentCountryNames;
      });
    });

    const actions = {
      closeModal: () => {
        emit("hideModal");
      },
      createGeoFence: () => {
        state.isSaving = true;
        const countries = [];
        state.selectedCountryList.forEach((country) => {
          let selectedCountry = state.countryOriginalData.find((countryObj) => {
            return countryObj.name === country;
          });
          if (selectedCountry) {
            countries.push(selectedCountry.code);
          }
        });

        emit("saveComplete", countries);
      },
      selectCountry: (item) => {
        const continents = [
          "Africa",
          "Asia",
          "Europe",
          "North-America",
          "Oceania",
          "South-America",
          "other",
        ];
        if (continents.includes(item)) {
          state.selectedCountryList = [
            ...new Set([
              ...state.countrylistNew[item],
              ...state.selectedCountryList,
            ]),
          ];
        } else {
          !state.selectedCountryList.includes(item) &&
            state.selectedCountryList.push(item);
        }
      },
      removeCountry: (item) => {
        let index = state.selectedCountryList.findIndex((country) => {
          return country === item;
        });
        state.selectedCountryList.splice(index, 1);
      },
      searchCountry: () => {
        const tempCountryList = state.storedCountryList;
        const query = state.query.toLowerCase();
        if (query.trim().length > 0) {
          state.countrylist = tempCountryList.filter((country) => {
            return country.toLowerCase().includes(query);
          });
        }
      },
    };

    return { state, actions };
  },
};
</script>

<style src="./style.css" scoped></style>
