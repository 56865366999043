
import { PropType } from "vue";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";
import ArrowIcon from "@/components/console/icons/ArrowIcon.vue";

type DefaultValue = string | number;

export default {
  name: "InputCount",
  components: { ArrowIcon, ButtonBasic },
  props: {
    label: {
      type: String as PropType<string>,
      default: "",
    },
    subLabel: {
      type: String,
      required: false,
    },
    defaultValue: {
      type: [String, Number] as PropType<DefaultValue>,
      default: "",
    },
    placeholder: {
      type: String as PropType<string>,
      default: "",
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    errorMessage: {
      type: String as PropType<string>,
      default: "",
    },
    caption: {
      type: String,
      required: false,
    },
    padding: {
      type: String,
      default: "14px 16px",
    },
    required: {
      type: Boolean,
      default: false,
    },
    step: {
      type: [String, Number],
      default: 1,
    },
    readonly: { type: Boolean, default: false },
  },
  emits: ["updateData"],
  setup(props, { emit }) {
    const updateValue = (type = "increase") => {
      const step = props.step;
      const val = props.defaultValue;
      let calculatedVal;
      const isIncrease = type === "increase";

      if (!isIncrease && val === 0) {
        return;
      }

      if (step !== 1) {
        calculatedVal = isIncrease
          ? Math.ceil((val + 1) / step) * step
          : Math.floor((val - 1) / step) * step;
      } else {
        calculatedVal = isIncrease ? val + step : val - step;
      }

      emit("updateData", calculatedVal);
    };

    const actions = {
      increaseData: () => {
        updateValue();
      },
      decreaseData: () => {
        updateValue("decrease");
      },
    };

    return { actions };
  },
};
