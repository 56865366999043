import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dd8261da"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-basic" }
const _hoisted_2 = {
  key: 0,
  class: "sub-text-s2"
}
const _hoisted_3 = { class: "sub-text-s2" }
const _hoisted_4 = {
  key: 0,
  class: "text-purple-50"
}
const _hoisted_5 = {
  key: 0,
  class: "sub-text-s3 text-gray-second sub-label"
}
const _hoisted_6 = { class: "input-wrapper" }
const _hoisted_7 = ["value", "placeholder", "disabled"]
const _hoisted_8 = { class: "button-wrapper" }
const _hoisted_9 = {
  key: 1,
  class: "info-message"
}
const _hoisted_10 = {
  key: 0,
  class: "caption sub-text-s3 text-gray-third"
}
const _hoisted_11 = {
  key: 1,
  class: "error sub-text-s3 text-red-50"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_arrow_icon = _resolveComponent("arrow-icon")!
  const _component_button_basic = _resolveComponent("button-basic")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", null, _toDisplayString($props.label), 1),
            ($props.required)
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, "*"))
              : _createCommentVNode("", true)
          ]),
          ($props.subLabel)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("small", null, _toDisplayString($props.subLabel), 1)
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("input", {
        value: $props.defaultValue,
        class: _normalizeClass(["sub-text-s2", { error: !!$props.errorMessage }]),
        type: "number",
        style: _normalizeStyle({
          padding: $props.padding,
        }),
        placeholder: $props.placeholder,
        disabled: $props.disabled,
        readonly: ""
      }, null, 14, _hoisted_7),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_button_basic, {
          "bg-color": "transparent",
          padding: "0",
          onAction: $setup.actions.increaseData
        }, {
          icon: _withCtx(() => [
            _createVNode(_component_arrow_icon, {
              width: "14",
              height: "14",
              direction: "up"
            })
          ]),
          _: 1
        }, 8, ["onAction"]),
        _createVNode(_component_button_basic, {
          "bg-color": "transparent",
          padding: "0",
          onAction: $setup.actions.decreaseData
        }, {
          icon: _withCtx(() => [
            _createVNode(_component_arrow_icon, {
              width: "14",
              height: "14",
              direction: "down"
            })
          ]),
          _: 1
        }, 8, ["onAction"])
      ])
    ]),
    (!!$props.errorMessage || !!$props.caption)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          (!!$props.caption)
            ? (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString($props.caption), 1))
            : _createCommentVNode("", true),
          (!!$props.errorMessage)
            ? (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString($props.errorMessage), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}